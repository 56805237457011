


























































import { defineComponent, ref, computed } from '@vue/composition-api';
import * as Rules from 'monkey-validates-form';
import Axios from 'axios';

export default defineComponent({
  setup(props, { root }) {
    const valid = ref(false);
    const loading = ref(false);
    const error: any = ref({
      code: null,
      on: false,
      message: null
    });
    const rules = [Rules.vRequired, Rules.vEmail];

    const email = ref('');

    const sendData = async () => {
      try {
        loading.value = true;
        await Axios.post('/session/recovery/_forgot', {
          email: email.value
          // crumb: root.$crumb,
        });
        loading.value = false;
        email.value = '';
        root.$router.push('/');
        alert('Email enviado');
      } catch (error) {
        loading.value = false;
        // const e = Debug.error(error);
        root.$store.commit('setSnack', {
          color: 'red',
          text: 'Error',
          show: true
        });
      }
    };
    return {
      email,
      rules,
      error,
      loading,
      sendData,
      valid
    };
  }
});
// import { Component, Vue } from 'vue-property-decorator';
// import { Debug } from '@/utils/helpers';
// import { builCommit, builActions } from '@/utils/vuex';
// import * as rules from 'monkey-validates-form';
// import { Session } from '@/services/Api';
// import Axios from 'axios';
// @Component
// export default class Users extends Vue {

// }
