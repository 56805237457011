import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from '@/config/plugins/vuetify';
import '@/config/plugins';
import '@/config/styles';

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
