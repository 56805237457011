import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Forgot from './views/Forgot.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: Forgot,
    },
  ],
});

// router.beforeEach((to, from, next) => {
//     // if (to.path === '/') {
//     //     next('/cuenta');
//     // } else {
//     //     next();
//     // }

//     // next();

//     // next('/cuentas#/cu');
//     if (to.path === '/cuenta') {
//         if (store.getters.isLogged) {
//             next();
//         } else {
//             next('/');
//         }
//     } else {
//         if (store.getters.isLogged) {
//             next('/cuenta');
//         } else {
//             next();
//         }
//     }

//     // ...
// });

export default router;
