


















































































import { defineComponent, ref, computed } from '@vue/composition-api';
import * as rules from 'monkey-validates-form';
import Axios from 'axios';

export default defineComponent({
  setup(props, { root }) {
    // const crumb = root.$crumb;
    const inputpass = ref(false);
    const valid = ref(false);
    const rEmail = [rules.vRequired, rules.vEmail];
    const rPassword = [rules.vRequired, rules.vMin(8)];
    const loading = ref(false);
    const error: any = ref({
      code: null,
      on: false,
      message: null
    });
    const log = ref({
      email: '',
      password: ''
    });
    const sendData = () => {
      loading.value = true;
      Axios.post('/session/login/normal', {
        ...{
          email: log.value.email,
          password: log.value.password
        }
      })
        .then(() => {
          location.href = '/profile';
        })
        .catch((err) => {
          loading.value = false;
          error.value.code = 'L002';
        });
    };
    const passErrors = computed(() => {
      if (error.value.code === 'L002') {
        return ['Contraseña o usuario incorrecta'];
      } else {
        return [];
      }
    });
    const emailErrors = computed(() => {
      if (error.value.code === 'L001') {
        return ['Este email no esta registrado'];
      } else {
        return [];
      }
    });
    return {
      inputpass,
      valid,
      rEmail,
      rPassword,
      error,
      loading,
      sendData,
      emailErrors,
      passErrors,
      log
    };
  }
});
